<style lang="scss">
.print-overview {
  h2 {
    //border-left: 0;
  }
  .bg-blue-light {
    position: relative;
    top: -60px;
    @media (max-width: $width-md) {
      top: 0;
    }
  }

  ul {
    line-height: 1.5em;
    li {
      position: relative;
      list-style: none;
      padding: 5px 0 5px 25px;
      &:before {
        content: '';
        position: absolute;
        left: 2px;
        top: 15px;

        width: 4px;
        height: 9px;
        border: solid $blue;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
      ul {
        li {
          position: relative;
          list-style: none;
          padding: 5px 0 5px 25px;
          &:before {
            content: '';
            position: absolute;
            left: 2px;
            top: 40%;

            width: 4px;
            height: 9px;
            border: solid $red;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  .tabgroup-kategorien {
    .title-container {
      @media (max-width: $width-lg) {
      }
      .title {
        @media (max-width: $width-lg) {
          width: 50%;
          display: inline-block;
        }
      }
      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }
    .col-lg-3 {
      //background-color:$blue-light;
      border: 1px solid #fff;
      background: #f9f9f9;
      //color:#fff;
      padding: 15px;
      text-align: center;
      position: relative;

      //margin:1px;
    }
  }
}
</style>

<template>
  <div class="print-overview">
    <div class="container margin-b-xl">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <h1>Wir machen Sie sichtbar.</h1>
          <h2>Wo Sie wollen. Wie Sie wollen.</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 col-md-10 padding-t-s">
          <div class="info-txt">
            Genau so individuell wie Ihre Werbeziele und Kommunikationsbotschaften sind die Platzierungsmöglichkeiten in der Kleinen Zeitung.<br />Wählen Sie zwischen Exklusiv-, Top-, Themen- und Basisplatzierung und schaffen Sie für Ihr Inserat maximale Aufmerksamkeit.<br />
            Mit dem flexiblen Millimeterpreis bestimmen Sie Ihr gewünschtes Format selbst (ausgenommen Exklusivplatzierung).
          </div>
        </div>
      </div>
      <!--
       <div class="whitespace-hori-line padding-t-s">
        <div class="line"></div>
      </div>-->
      <!--<TabGroup class="tabgroup-kategorien">
        <Tab tabid="kategorie-tab-a" :title="'Exklusivplatzierung'" active>
          <div class="row justify-content-center">
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Titelseite</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Thema des Tages</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Börse</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Wetter</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Leserforum</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Rätselseite / Liebe ist</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">TV-Programm</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">U3</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Leute / U4</div>
          </div>
        </Tab>
        <Tab tabid="kategorie-tab-b" :title="'Top-Platzierung Politik'">
          <div class="row justify-content-center">
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Politik</div>
          </div>
        </Tab>
        <Tab tabid="kategorie-tab-c" :title="'Themen-Platzierung'">
          <div class="row justify-content-center">
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">International</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Österreich</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Bundesland</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Wirtschaft</div>
          </div>
        </Tab>
        <Tab tabid="kategorie-tab-d" :title="'Basis-Platzierung'">
          <div class="row justify-content-center">
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Besser leben</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Top-Tipps</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Anzeigenteil</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Sport</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Kultur</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Medien</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Wohnen</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Möbilität</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Samstagsbeilage</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Reise</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Gesundheit</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Beruf &amp; Bildung</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Sonntagsbeilage</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Familie</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Heim &amp; Garten</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Essen &amp; Trinken</div>
            <div class="col-lg-3 col-md-4 col-sm-6 ol-10">Mode &amp; Lifestyle</div>
          </div>
        </Tab>
      </TabGroup>-->
    </div>
    <div class="bg-blue-light margin-b-m">
      <div class="wrapper-xxl outer-slider">
        <!-- -->
        <div class="print-overview-slider">
          <!-- <Slider :delay="4000" :items="{ 768: 2, 1024: 3, 1400: 3, 1600: 3 }" easing="linear" :speed="500" autoplay noclone>-->
          <Slider :delay="4000" :items="{ 768: 2, 1024: 2, 1400: 3, 1600: 3 }" easing="linear" :speed="500" autoplay>
            <template #items>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/1_Titel_Exklusiv.jpg" alt="Exklusivplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Exklusivplatzierung</div>
                  <router-link :to="{ name: 'Exklusivplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/2_Politik-1-von-2_Top.jpg" alt="Top-Platzierung Politik" class="img-fluid" />
                  </div>
                  <div class="kat">Top-Platzierung Politik</div>
                  <router-link :to="{ name: 'TopplatzierungPolitik' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/2_Politik-2-von-2_Top.jpg" alt="Top-Platzierung Politik" class="img-fluid" />
                  </div>
                  <div class="kat">Top-Platzierung Politik</div>
                  <router-link :to="{ name: 'TopplatzierungPolitik' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/5_International-1-von-2_Themen.jpg" alt="Themenplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Themenplatzierung</div>
                  <router-link :to="{ name: 'Themenplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/5_International-2-von-2_Themen.jpg" alt="Top-Platzierung Politik" class="img-fluid" />
                  </div>
                  <div class="kat">Top-Platzierung Politik</div>
                  <router-link :to="{ name: 'Themenplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/6_Oesterreich-1-von-2_Themen.jpg" alt="Themenplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Themenplatzierung</div>
                  <router-link :to="{ name: 'Themenplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/6_Oesterreich-2-von-2_Themen.jpg" alt="Themenplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Themenplatzierung</div>
                  <router-link :to="{ name: 'Themenplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/7_Steiermark-1-von-2_Themen.jpg" alt="Themenplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Themenplatzierung</div>
                  <router-link :to="{ name: 'Themenplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/7_Steiermark-2-von-2_Themen.jpg" alt="Themenplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Themenplatzierung</div>
                  <router-link :to="{ name: 'Themenplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/9_Regionalseite_Regional.jpg" alt="Regional" class="img-fluid" />
                  </div>
                  <div class="kat">Regional</div>
                  <router-link :to="{ name: 'Regional' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/9b_Regionalseite_Regional.jpg" alt="Regional" class="img-fluid" />
                  </div>
                  <div class="kat">Regional</div>
                  <router-link :to="{ name: 'Regional' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/10_Wirtschaft-1-von-2_Themen.jpg" alt="Themenplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Themenplatzierung</div>
                  <router-link :to="{ name: 'Themenplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/10_Wirtschaft-2-von-2_Themen.jpg" alt="Themenplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Themenplatzierung</div>
                  <router-link :to="{ name: 'Themenplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/10a_Wirtschaft_Boerse_Exklusiv.jpg" alt="Exklusivplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Exklusivplatzierung</div>
                  <router-link :to="{ name: 'Exklusivplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/11a_Besser-Leben-1-von-2_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Basisplatzierung</div>
                  <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/11a_Besser-Leben-2-von-2_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Basisplatzierung</div>
                  <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/13_Mein-Tag-1-von-2_Exklusiv.jpg" alt="Exklusivplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Exklusivplatzierung</div>
                  <router-link :to="{ name: 'Exklusivplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/13_Mein-Tag-2-von-2_Exklusiv.jpg" alt="Exklusivplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Exklusivplatzierung</div>
                  <router-link :to="{ name: 'Exklusivplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/13a_Mein-Tag-Einzelseite_Exklusiv.jpg" alt="Exklusivplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Exklusivplatzierung</div>
                  <router-link :to="{ name: 'Exklusivplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/14_Mobilitaet_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Basisplatzierung</div>
                  <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/15_Wohnen_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Basisplatzierung</div>
                  <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/16_Beruf-und-Bildung-Wochentags_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Basisplatzierung</div>
                  <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/18_Sport-1-von-2_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Basisplatzierung</div>
                  <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/18_Sport-2-von-2_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Basisplatzierung</div>
                  <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/21_Kultur-1-von-2_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Basisplatzierung</div>
                  <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/21_Kultur-2-von-2_Basis.jpg" alt="Basisplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Basisplatzierung</div>
                  <router-link :to="{ name: 'Basisplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/22_TV-1-von-2_Exklusiv.jpg" alt="Exklusivplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Exklusivplatzierung</div>
                  <router-link :to="{ name: 'Exklusivplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/22_TV-2-von-2_Exklusiv.jpg" alt="Exklusivplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Exklusivplatzierung</div>
                  <router-link :to="{ name: 'Exklusivplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
              <SliderItem>
                <div class="inner-box">
                  <div class="img-box">
                    <img src="@/assets/img/img-content/print/platzierungen/23_Leute_Exklusiv.jpg" alt="Exklusivplatzierung" class="img-fluid" />
                  </div>
                  <div class="kat">Exklusivplatzierung</div>
                  <router-link :to="{ name: 'Exklusivplatzierung' }" class="stretched-link"></router-link>
                </div>
              </SliderItem>
            </template>
            <template #navigation="{ left, right }">
              <div class="slider-navi-outer">
                <div @click="right"><span class="material-icons arrow-btn arrow-left">arrow_back_ios</span></div>
                <div @click="left"><span class="material-icons arrow-btn arrow-right">arrow_forward_ios</span></div>
              </div>
            </template>
          </Slider>
        </div>
        <!-- -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Slider from '@/components/util/Slider.vue';
import SliderItem from '@/components/util/SliderItem.vue';

export default defineComponent({
  components: {
    Slider,
    SliderItem,
  },
});
</script>
